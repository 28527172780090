import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";

import { UserContextProvider } from "./context/context";

import FormScreen from "./pages/FormScreen";
import HomePage from "./pages/HomePage";
import NotFound from "./pages/NotFound";
function App() {
  // useEffect(() => {
  //   async function sendVersionToTelegram() {
  //     const version = packageJson.version;
  //     await axios.post(
  //       `${API_URL}core/ping`,
  //       {
  //         message: `${API_URL_FE} đã deploy phiên bản mới ${version}`,
  //         version,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           responseType: "blob",
  //           "x-auth-uuid": "",
  //         },
  //         withCredentials: true,
  //       }
  //     );
  //   }
  //   sendVersionToTelegram();
  //   return () => sendVersionToTelegram();
  // }, []);
  return (
    <UserContextProvider>
      <Routes>
        <Route path="/form" element={<FormScreen />} />
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </UserContextProvider>
  );
}

export default App;
