import React, { Dispatch, createContext, useReducer } from "react";
import { notifySuccess } from "../components/notification";
// const initialState = {
//   cart: Cookies.get("user")
//     ? JSON.parse(Cookies.get("user"))
//     : { username: "", email: "" },
// };

const initialState = {
  user: { username: "", email: "" },
};
function reducer(state, action) {
  switch (action.type) {
    case "LOGIN": {
      return { ...state, user: { email: action.payload.email } };
    }
    default:
      return state;
  }
}
export const UserContext = createContext({
  state: initialState,
  dispatch: (type, product) => null,
});

export const UserContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};
