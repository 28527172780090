import React from "react";
import imgBlock2 from "../assets/images/block2.jpg";
import imgBlock3 from "../assets/images/block3.png";
import imgPost1 from "../assets/images/post1.png";
import imgPost2 from "../assets/images/post2.png";
import imgPost3 from "../assets/images/post3.jpg";
import imgPost4 from "../assets/images/post4.png";
import tuthien1 from "../assets/images/tuthien/tuthien1.jpg";
import tuthien2 from "../assets/images/tuthien/tuthien2.jpg";
import tuthien3 from "../assets/images/tuthien/tuthien3.jpg";
import tuthien4 from "../assets/images/tuthien/tuthien4.jpg";
import main1 from "../assets/images/main/main1.JPG";
import main2 from "../assets/images/main/main2.JPG";
import main3 from "../assets/images/main/main3.jpg";
import main4 from "../assets/images/main/main4.JPG";
import main5 from "../assets/images/main/main5.JPG";
import main6 from "../assets/images/main/main6.JPG";
import main7 from "../assets/images/main/main7.JPG";
import main8 from "../assets/images/main/main8.JPG";
import { FaTiktok, FaYoutube, FaFacebook } from "react-icons/fa";
import Slider from "react-slick";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";

const HomePage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="homepage container">
      <div className="homepage__block1">
        <div className="homepage__block1__description">
          <h4>Chào mừng đến với</h4>
          <h3>CỘNG ĐỒNG DNI INVEST</h3>
          <p>
            Lý tưởng chủ đạo là giúp 10.000 thanh niên Việt Nam tự do tài chính
          </p>
          <a href="https://t.me/dniinvest">ĐĂNG KÝ NGAY</a>
        </div>
        <div className="homepage__block1__slide">
          <Slider {...settings}>
            <div className="homepage__block1__slide__image">
              <img src={main1} href="block2" />
            </div>
            <div className="homepage__block1__slide__image">
              <img src={main2} href="block2" />
            </div>
            <div className="homepage__block1__slide__image">
              <img src={main3} href="block2" />
            </div>
            <div className="homepage__block1__slide__image">
              <img src={main4} href="block2" />
            </div>
            <div className="homepage__block1__slide__image">
              <img src={main5} href="block2" />
            </div>
            <div className="homepage__block1__slide__image">
              <img src={main6} href="block2" />
            </div>
          </Slider>
        </div>
      </div>
      <div className="homepage__block2">
        <div>
          <img src={imgBlock2} href="block2" />
        </div>
      </div>
      <div className="homepage__block3">
        <div className="homepage__block3__main">
          <div className="homepage__block3__main__image">
            <img src={imgBlock3} href="block3" />
          </div>
          <div className="homepage__block3__main__description">
            <h4>CUỘC THI TRADING CUỐI CÙNG TRONG NĂM 2023</h4>
            <p>VỚI TỔNG GIẢI THƯỞNG LÊN ĐẾN 100.000.000 ĐỒNG</p>
            <span>
              Thời gian diễn ra: 00h00’ ngày 11/12/2023 - 23h59’ ngày 22/12/2023
            </span>
            <a href="https://dniinvest.com/form?utm_source=trading_contest_12_2023">
              Đăng ký tham gia ngay
            </a>
          </div>
        </div>
      </div>
      <div className="homepage__block4">
        <div className="homepage__block4__main">
          <div className="homepage__block4__main__description">
            <h4>Các kỳ thi trading cấp vốn DNI đã tổ chức</h4>

            <span>
              Rất nhiều giải thưởng bao gồm cả hiện kim lẫn hiện vật đã được DNI
              trao đến những traders xuất sắc nhất qua từng kì thi
            </span>
          </div>
          <div className="homepage__block4__main__image">
            <div className="homepage__block4__main__image__image1">
              <img src={imgPost1} href="block3" />
            </div>
            <div className="homepage__block4__main__image__image2">
              <img src={imgPost2} href="block3" />
            </div>

            <div className="homepage__block4__main__image__image3">
              <img src={imgPost3} href="block3" />
            </div>

            <div className="homepage__block4__main__image__image4">
              <img src={imgPost4} href="block3" />
            </div>
          </div>
        </div>
      </div>

      <div className="homepage__block5">
        <h4 style={{ width: "100%" }}>
          Các hoạt động thiện nguyện của chúng tôi
        </h4>

        <Slider {...settings} className="homepage__block5__slider">
          <div className="homepage__block5__slider__image">
            <img src={tuthien1} href="block2" />
          </div>
          <div className="homepage__block5__slider__image">
            <img src={tuthien2} href="block2" />
          </div>
          <div className="homepage__block5__slider__image">
            <img src={tuthien3} href="block2" />
          </div>
          <div className="homepage__block5__slider__image">
            <img src={tuthien4} href="block2" />
          </div>
        </Slider>
      </div>
      <div className="homepage__footer">
        <div className="homepage__footer__description">
          <h4>CÔNG TY CỔ PHẦN ĐẦU TƯ DNI VIỆT NAM</h4>
          <p>
            Địa chỉ: 56 đường số 1, Cityland Center Hills, Gò Vấp, Thành phố Hồ
            Chí Minh
          </p>
          <p>Email: it.dniinvest@gmail.com</p>
          <p>Hotline: 0356.670.756</p>
        </div>
        <div className="homepage__footer__link">
          <h4>Kết nối với chúng tôi</h4>
          <a href="https://www.facebook.com/dni.invest" target="_blank">
            <FaFacebook />
            &nbsp; Facebook
          </a>
          <a href="https://www.youtube.com/@DoanNamInvest" target="_blank">
            <FaYoutube />
            &nbsp; Youtube
          </a>
          <a href="https://www.facebook.com/dni.invest" target="_blank">
            <FaTiktok />
            &nbsp; Tiktok
          </a>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
