import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="mainBackground">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
          flexWrap: "wrap",
          width: "100%",
          height: "100vh",
        }}
      >
        <div
          style={{
            fontSize: "48px",
            color: "#fff",
            fontWeight: "700",
            width: "100%",
            textAlign: "center",
          }}
        >
          OOPS!
        </div>
        <div
          style={{
            fontSize: "36px",
            color: "#fff",
            width: "100%",
            textAlign: "center",
            marginTop: "12px",
            cursor: "pointer",
          }}
          onClick={() => navigate("/")}
        >
          Trang không tồn tại, về trang chủ.
        </div>
      </div>
    </div>
  );
};

export default NotFound;
